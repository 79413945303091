import { AuthenticationProvider } from '~context/Authentication';
import { BrowserRouter } from 'react-router-dom';
import { LanguageProvider } from '~context/Language';
import { PUBLIC_AUTH_URI } from '~constants/auth';
import AppRoutes from './AppRoutes';
import React from 'react';

export default function App(): JSX.Element {
    return (
        <AuthenticationProvider authUrl={PUBLIC_AUTH_URI}>
            <BrowserRouter>
                <LanguageProvider>
                    <AppRoutes />
                </LanguageProvider>
            </BrowserRouter>
        </AuthenticationProvider>
    );
}
