import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { color, gradient, space } from '~styles/styles';
import { useAuthentication } from '~context/Authentication';
import Loader, { ELoaderVariant } from '@makespace/ui-components/components/Loader';
import React, { Suspense, lazy } from 'react';
import styled from '@emotion/styled';

const LoginPage = lazy(() => import('~pages/LoginPage'));
const RegisterPage = lazy(() => import('~pages/RegisterPage'));
const AccessPage = lazy(() => import('~pages/AccessPage'));

export enum EAppRoutes {
    ACCESS = 'access',
    LOGIN = 'login',
    REGISTER = 'register',
    VERIFY = 'verify',
}

export default function AppRoutes(): JSX.Element {
    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Routes>
                <Route element={<RequireUnAuthenticated />}>
                    <Route element={<LayoutPublic />}>
                        <Route path={EAppRoutes.LOGIN} element={<LoginPage />} />
                        <Route path={EAppRoutes.REGISTER} element={<RegisterPage />} />
                    </Route>
                </Route>
                <Route element={<RequireAuthenticated />}>
                    <Route element={<LayoutPublic />}>
                        <Route path={`${EAppRoutes.ACCESS}/:resource`} element={<AccessPage />} />
                        <Route path={EAppRoutes.VERIFY} element={<p>verify</p>} />
                    </Route>
                </Route>
                <Route path={'*'} element={<UnknownRoute />} />
            </Routes>
        </Suspense>
    );
}

function RequireAuthenticated(): JSX.Element {
    const { isAuthenticated, loading, error } = useAuthentication();
    const location = useLocation();
    return !isAuthenticated && loading ? (
        <p>loading...</p>
    ) : isAuthenticated ? (
        <Outlet />
    ) : error ? (
        <p>{error.message}</p>
    ) : (
        <Navigate
            to={`/${EAppRoutes.LOGIN}`}
            state={
                location.pathname === '/logout'
                    ? '/'
                    : { authedReturnTo: `${location.pathname}${location.search}` }
            }
            replace={true}
        />
    );
}

function RequireUnAuthenticated(): JSX.Element {
    const { isAuthenticated, isAuthenticationReady } = useAuthentication();
    const location = useLocation();
    const { authedReturnTo } = (location?.state as { authedReturnTo?: string }) ?? {};

    return !isAuthenticationReady ? (
        <Loader variant={ELoaderVariant.OVERLAY} />
    ) : isAuthenticated ? (
        <Navigate to={authedReturnTo ? authedReturnTo : '/'} />
    ) : (
        <Outlet />
    );
}

function UnknownRoute(): JSX.Element {
    return <div>Route Unknown</div>;
}

function LayoutPublic() {
    return (
        <PublicLayout>
            <Content>
                <Outlet />
            </Content>
            <Space />
        </PublicLayout>
    );
}

const PublicLayout = styled.div`
    label: PublicLayout;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    gap: 0;
    background: ${gradient.purpleBlack};
`;

const Space = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    gap: 0;
    flex: 1 1;
    min-height: 100vh;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: ${space.gap.medium};
    padding: ${space.padding.normal} ${space.padding.normal} 0 ${space.padding.normal};
    flex-grow: 0;
    min-height: 100vh;
    width: 33vw;
    min-width: 500px;
    background: ${color.white};
    border-right: 3px solid ${color.highlightDark};
`;
