import { color } from '../styles/styles';
import React from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';

export enum ELoaderVariant {
    NORMAL = 'normal',
    OVERLAY = 'overlay',
}

export enum ELoaderSize {
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small',
}

type LoaderProps = {
    message?: string;
    size?: ELoaderSize;
    variant?: ELoaderVariant;
};

export default function Loader(props: LoaderProps): JSX.Element {
    const { variant = ELoaderVariant.NORMAL, size = ELoaderSize.MEDIUM, message } = props;
    return (
        <LoaderContainer className={variant}>
            <span className={clsx('spinner', size)} />
            {message ? <span className={'message'}>{`${message ?? ''}...`}</span> : null}
        </LoaderContainer>
    );
}

const LoaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    & .spinner {
        display: block;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            z-index: 1;
            background: conic-gradient(
                from 180deg at 50% 50%,
                #efedfc 0deg,
                #847eb1 35deg,
                #4d4787 62.5deg,
                #847eb1 90deg,
                #efedfc 135deg
            );
            transform-origin: 50% 50%;
            animation: spin 1s infinite forwards linear;
        }

        &:after {
            content: '';
            position: absolute;
            width: 90%;
            height: 90%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            border-radius: 50%;
            z-index: 2;
        }
    }

    &.${ELoaderVariant.OVERLAY} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.98);
        z-index: 9999;
    }

    & .message {
        color: ${color.greyLight};
    }
`;
